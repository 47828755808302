import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../components/layout"
import Problems from "../../components/problems/problems"
import ItemCarousel from "../../components/Treatments/TreatmentsCarousel/ItemCarousel/ItemCarousel"
import style from "./problem.module.scss"
import ReactMarkdown from "react-markdown"
import Img from "gatsby-image"
import SEO from "../../components/seo"

function Problem({ data }) {
  const problem = data.strapiProblem

  const treatmentsBlock = () => {
    if (data.strapiProblem.zabiegs.length > 0) {
      return (
        <div className={style.TreatmentsBlock}>
          <h1 className={style.TitleTreatments}>Proponujemy</h1>
          <ItemCarousel items={data.strapiProblem.zabiegs} playSpeed={5000} />
        </div>
      )
    } else {
      return (
        <div className={style.TreatmentsBlock}>
          <h1 className={style.TitleTreatments}>
            <Link to={'/kontakt'} style={{textDecoration: 'none', color: 'black'}}> Prosimy o kontakt w sprawie tego problemu...</Link>
          </h1>
        </div>
      )
    }
  }
  return (
    <Layout>
      <SEO
        description={`${problem.tytul}? Pomożemy ci lepiej wyglądać`}
        title={problem.tytul}
      />
      <section className={style.Problem}>
        <Img
          className={style.ProblemImage}
          fluid={problem.obrazek.childImageSharp.fluid}
        ></Img>
        <h1 className={style.TitleProblem}>{problem.tytul}</h1>
        <ReactMarkdown
          source={problem.opis}
          className={style.Text}
          transformImageUri={uri =>
            uri.startsWith("http") ? uri : `${process.env.IMAGE_BASE_URL}${uri}`
          }
        />
      </section>

      {treatmentsBlock()}
      <Problems title={"Zobacz też inne!"} />
    </Layout>
  )
}

export const query = graphql`
  query problemTemplate($id: String!) {
    strapiProblem(id: { eq: $id }) {
      id
      obrazek {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      opis
      tytul
      zabiegs {
        Cena
        Nazwa
        Opis
        pozycja_na_stronie
        Zdjecie {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        id
      }
    }
  }
`

export default Problem
