import React, { useState, useRef, useEffect } from "react"
import TreatmentBlock from "../../TreatmentBlock/TreatmentBlock"
import TreatmentModal from "../../TreatmentModal/TreatmentModal"
import Carousel from "react-elastic-carousel"
import style from "./ItemCarousel.module.scss"

const closeModal = setModal => {
  setModal(null)
}

const createTreatments = (treatments, setModal) => {
  return treatments.map((value, id) => {
    let data

    if (value.node !== undefined) {
      data = value.node
    } else data = value

    return (
      <TreatmentBlock
        key={id}
        onClick={() =>
          showModal(
            data.Nazwa,
            data.Zdjecie,
            data.Cena,
            data.Czas,
            data.Opis,
            setModal
          )
        }
        name={data.Nazwa}
        img={data.Zdjecie}
        price={data.Cena}
        time={data.Czas}
      />
    )
  })
}

const showModal = (name, img, price, time, desc, setModal) => {
  const newModal = (
    <TreatmentModal
      img={img}
      desc={desc}
      name={name}
      price={price}
      time={time}
      close={() => closeModal(setModal)}
    />
  )
  setModal(newModal)
}

function getLastIndex(width, breakPoints, itemsLength) {
  let itemsPerPage = 1
  for (let i = 0; i < breakPoints.length; i++) {
    if (width > breakPoints[i].width) {
      itemsPerPage = breakPoints[i].itemsToShow
    }
  }

  let lastIndex = itemsLength - itemsPerPage + 1

  return lastIndex
}

function ItemCarousel(props) {
  const [width, setWidth] = useState(0)
  const [modal, setModal] = useState()
  const playSpeed = props.playSpeed
  const itemsNumber = props.items.length
  const carouselRef = useRef()
  let resetTimeout

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 850, itemsToShow: 3 },
    { width: 10000, itemsToShow: 4 },
  ]

  useEffect(() => {
    setWidth(window.innerWidth)

    window.addEventListener("resize", () => {
      setWidth(window.innerWidth)
    })

    return () => {
      window.removeEventListener("resize", () => {})
    }
  }, [])

  const onNextStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      // we hit the last item, go to first item
      carouselRef.current.goTo(0)
    }
  }

  const onPrevStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      // we hit the first item, go to last item
      carouselRef.current.goTo(itemsNumber)
    }
  }

  return (
    <>
      <div id={style.modal} style={{ position: "fixed", zIndex: 300 }}>
        {modal ?? null}
      </div>
      <Carousel
        breakPoints={breakPoints}
        showArrows={false}
        enableAutoPlay
        autoPlaySpeed={playSpeed}
        ref={carouselRef}
        onPrevStart={onPrevStart}
        onNextStart={onNextStart}
        onNextEnd={({ index }) => {
          clearTimeout(resetTimeout)
          if (index + 1 === getLastIndex(width, breakPoints, itemsNumber)) {
            resetTimeout = setTimeout(() => {
              carouselRef.current.goTo(0)
            }, playSpeed / 2)
          }
        }}
      >
        {createTreatments(props.items, setModal)}
      </Carousel>
    </>
  )
}

export default ItemCarousel
