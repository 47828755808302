import React from "react"
import Img from "gatsby-image"

import classes from "./TreatmentBlock.module.scss"

const TreatmentBlock = ({ name, img, price, onClick }) => {
  return (
    <div
      className={classes.treatment_block}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex={0}
    >
      <Img
        className={classes.image}
        fluid={img.childImageSharp.fluid}
        alt={name}
      />
      <h2 className={classes.name}>
        {name.length > 40 ? name.slice(0, 40) + "..." : name}
      </h2>
      <div className={classes.data}>
        <p className={classes.labels}>Cena</p>
        <p className={classes.values}>{price}</p>
      </div>
      <button className={classes.button}>Więcej</button>
    </div>
  )
}

export default TreatmentBlock
