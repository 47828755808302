import React from "react"
import Img from "gatsby-image"
import { Link } from 'gatsby'
import ReactMarkdown from "react-markdown"

 
import classes from "./TreatmentModal.module.scss"
import CloseIcon from "@material-ui/icons/Close"

const TreatmentModal = ({ img, desc, name, price, time, close }) => {
  return (
    <div className={classes.container}>
      <div className={classes.modal}>
        <CloseIcon className={classes.close} onClick={() => close()} />
        {/* <div className={classes.left_side}> */}
          <Img
            className={classes.image}
            fluid={img.childImageSharp.fluid}
            alt={name}
          />
          <div className={classes.button}>
            <Link to={`/cennik/#${name}`}>
              <button
                onClick={() => {
                  close()
                }}
              >
                Cennik
              </button>
            </Link>
          </div>
        {/* </div> */}
        {/* <div className={classes.right_side}> */}
          <div className={classes.text}>
            <h2 className={classes.name}>{name}</h2>
            <ReactMarkdown className={classes.desc}>{desc}</ReactMarkdown>
          </div>
          {/* <div className={classes.details}>
            <div className={classes.labels}>
              <p>Cena</p>
            </div>
            <div className={classes.values}>
              <p>{price}</p>
            </div>
          </div> */}
        {/* </div> */}
      </div>
      <div
        className={classes.backdrop}
        onClick={close}
        onKeyDown={close}
        role="button"
        tabIndex={0}
        aria-label="Close modal"
      />
    </div>
  )
}

export default TreatmentModal
